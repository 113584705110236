
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionTwoColumnWithImage2 from "@/components/SectionTwoColumnWithImage2/SectionTwoColumnWithImage2.vue";
import fetchJapaneseDepositWithdraw from "@/misc/composition/fetchK8JapaneseDepositWithdraw";
import OneArticleSection from "@/components/OneArticleSection/OneArticleSection.vue";

export default defineComponent({
  name: "Wallet Info",
  components: {
    SectionTwoColumnWithImage2,
    CasinoHeadBanner,
    LoadingPageAnim,
    OneArticleSection
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "クリプトにおけるフィアットの台頭"
    Object.keys(this.japaneseDepositWithdrawSEO).forEach((k, i) => {
      let d = Object.values(this.japaneseDepositWithdrawSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{rel: 'canonical', href: 'https://www.k8club.io/japanese-bank-deposit-withdraw'}],
      title: title,
      meta: metaTags,
    }
  },
  setup() {
    const {
      japaneseDepositWithdraw,
      japaneseDepositWithdrawSEO,
      pageInfo,
      warningInfo,
      loading
    } = fetchJapaneseDepositWithdraw();
    return {
      japaneseDepositWithdraw,
      japaneseDepositWithdrawSEO,
      pageInfo,
      warningInfo,
      loading
    };
  },
});
