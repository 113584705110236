import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a995d2ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative main-header overflow-hidden" }
const _hoisted_2 = { class: "container container-slider-cards" }
const _hoisted_3 = { class: "container container-bonuses" }
const _hoisted_4 = { class: "container-call-to-action" }
const _hoisted_5 = { class: "container container-bonuses" }
const _hoisted_6 = { class: "container container-bonuses" }
const _hoisted_7 = { class: "section-faq fluid-container hide-on-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_CardGamesSlider = _resolveComponent("CardGamesSlider")!
  const _component_SectionGameInfo = _resolveComponent("SectionGameInfo")!
  const _component_CallToActionSection = _resolveComponent("CallToActionSection")!
  const _component_SectionInfoPachinko = _resolveComponent("SectionInfoPachinko")!
  const _component_SectionTwoColumnWithImage = _resolveComponent("SectionTwoColumnWithImage")!
  const _component_SectionFaqHorseBetting = _resolveComponent("SectionFaqHorseBetting")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_a_layout_content, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_main_header, {
                    title: _ctx.pachinkoBannerHeader.title,
                    subtitle: _ctx.pachinkoBannerHeader.subtitle,
                    description: _ctx.pachinkoBannerHeader.description,
                    href: _ctx.pachinkoBannerHeader.button.href,
                    buttonName: _ctx.pachinkoBannerHeader.button.name,
                    "bg-images": _ctx.pachinkoBannerHeader.image
                  }, null, 8, ["title", "subtitle", "description", "href", "buttonName", "bg-images"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_CardGamesSlider, { items: _ctx.pachinkoSliderItem }, null, 8, ["items"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_SectionGameInfo, { "game-info": _ctx.gameInfo }, null, 8, ["game-info"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_CallToActionSection, {
                    image: _ctx.pachinkoMisSec.image,
                    title: _ctx.pachinkoMisSec.title,
                    description: _ctx.pachinkoMisSec.description
                  }, null, 8, ["image", "title", "description"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_SectionInfoPachinko, { info: _ctx.pachinkoHeader }, null, 8, ["info"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_SectionTwoColumnWithImage, { info: _ctx.providerInfo }, null, 8, ["info"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_SectionFaqHorseBetting, { thumbnail: {url:'images/demosimages/pachinko_page_desktop_mockup.webp'} }, null, 8, ["thumbnail"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}