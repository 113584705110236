
import { defineComponent } from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchK8LiveCasinoInfo from "@/misc/composition/fetchK8LiveCasinoInfo";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import SectionLiveCasino from "@/components/SectionLiveCasino/SectionLiveCasino.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionInfo from "@/components/SectionInfo/SectionInfo.vue";
import SectionTwoColumnWithImage from "@/components/SectionTwoColumnWithImage/SectionTwoColumnWithImage.vue";
import SectionGameInfo from "@/components/SectionGameInfo/SectionGameInfo.vue";
import OneArticleSection from "@/components/OneArticleSection/OneArticleSection.vue";

export default defineComponent({
  name: "Live Casino",
  components: {
    CasinoHeadBanner,
    LoadingPageAnim,
    SectionLiveCasino,
    SectionInfo,
    SectionTwoColumnWithImage,
    SectionGameInfo,
    OneArticleSection,
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "ライブカジノ"
    Object.keys(this.liveCasinoSEO).forEach((k,i)=>{
      let d =Object.values(this.liveCasinoSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{ rel: 'canonical', href: 'https://www.k8club.io/live-casino'}],
      title: title,
      meta: metaTags
    }
  },
  setup() {
    const {
      k8LiveCasinoInfo,
      liveCasinoSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      loading: k8LiveCasinoInfoLoading
    } = fetchK8LiveCasinoInfo();

    return {
      k8LiveCasinoInfo,
      liveCasinoSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      k8LiveCasinoInfoLoading
    };
  },
});
