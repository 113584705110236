
import { defineComponent, PropType } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);
import "swiper/swiper-bundle.min.css";

// Import Swiper styles
import "swiper/swiper.min.css";
import CardGameB from "../CardGameB/CardGameB.vue";
import { CardGameB as CardsType } from "./../CardGameB/CardGameB";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    CardGameB,
  },
  props: {
    items: {
      type: Object as PropType<CardsType[]>,
    },
  },
});
