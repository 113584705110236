import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeading, {
      class: "subtitle text-start",
      title: _ctx.pageInfo.title,
      subtitle: _ctx.pageInfo.subtitle,
      description: _ctx.pageInfo.description
    }, null, 8, ["title", "subtitle", "description"])
  ]))
}