
import {defineComponent, PropType} from "vue";
import {Thumbnail} from "@/types";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    href: {
      type: String,
    },
  },
});
