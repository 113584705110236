
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchK8BlockchainInfo from "@/misc/composition/fetchK8BlockchainInfo";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import SectionBlockchain from "@/components/SectionBlockchain/SectionBlockchain.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionInfo from "@/components/SectionInfo/SectionInfo.vue";
import OneArticleSection from "@/components/OneArticleSection/OneArticleSection.vue";
import SectionGameInfo from "@/components/SectionGameInfo/SectionGameInfo.vue";
import SectionTwoColumnWithImage from "@/components/SectionTwoColumnWithImage/SectionTwoColumnWithImage.vue";

export default defineComponent({
  name: "Blockchain",
  components: {
    CasinoHeadBanner,
    SectionBlockchain,
    LoadingPageAnim,
    SectionInfo,
    OneArticleSection,
    SectionGameInfo,
    SectionTwoColumnWithImage,
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "ブロックチェーン"
    Object.keys(this.blockchainSEO).forEach((k, i) => {
      let d = Object.values(this.blockchainSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{ rel: 'canonical', href: 'https://www.k8club.io/blockchain'}],
      title: title,
      meta: metaTags
    }
  },
  setup() {
    const {
      k8BlockchainInfo,
      blockchainSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      loading: k8BlockchainInfoLoading
    } = fetchK8BlockchainInfo();

    return {
      k8BlockchainInfo,
      blockchainSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      k8BlockchainInfoLoading
    };
  },
});
