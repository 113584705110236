
import {defineComponent} from "vue";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import BitcoinInfo from "@/components/BitcoinInfo/BitcoinInfo.vue";
import fetchK8PromotionInfo from "@/misc/composition/fetchPromotion";
import fetchVipLvlList from "@/misc/composition/fecthVipLevel";

export default defineComponent({
  name: "VIP Level Card",
  components: {
    BitcoinInfo,
    SectionHeading,
  },
  setup() {
    const {
      k8PromotionInfo,
      loading
    } = fetchK8PromotionInfo();
    const {vipLevelList, loading: vipLevelListLoading} = fetchVipLvlList();
    return {
      k8PromotionInfo,
      loading,
      vipLevelList,
      vipLevelListLoading
    };
  },
});
