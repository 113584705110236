import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image, Link} from "@/types";

type K8WalletInfo = {
    data: {
        bannerHeader: {
            title: string,
            subtitle: string,
            description: string,
            button: Link,
            image: Image,
        },
        seo: {},
        withdrawalMethod: [],

    }
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

export default function fetchWalletInfo() {
    const walletInfo = ref<K8WalletInfo>({} as K8WalletInfo);
    const walletSEO = ref<SEO>({} as SEO);
    const loading = ref<boolean>(true);
    const fetchWalletInfo = () => {
        getSingle<K8WalletInfo>(GET.K8_WALLET_INFO).then((result) => {
            loading.value = false;
            walletInfo.value = camelizeKeys(result.data)
            walletSEO.value = camelizeKeys(result.data.seo)
        });
    };
    fetchWalletInfo();
    return {
        walletInfo,
        walletSEO,
        loading,
        fetchWalletInfo
    }
}
