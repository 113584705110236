
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    title: String,
    description: String,
    // href: Object as PropType<String | Object>,
  },
});
