import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-625a1e54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-section center" }
const _hoisted_2 = { class: "content center flex-column" }
const _hoisted_3 = { class: "center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = { class: "title mb-0" }
const _hoisted_7 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "action-section-logo",
              src: _ctx.$getFileSource(_ctx.image.url),
              alt: ""
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1)
      ]),
      _createVNode(_component_a_button, {
        type: "link",
        href: "https://playk8.io/?invite=k8zone",
        class: "general-btn action-btn no-anim"
      }, {
        default: _withCtx(() => [
          _createTextVNode("今すぐ参加する")
        ]),
        _: 1
      })
    ])
  ]))
}