import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93df2036"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deals-cards d-flex justify-content-around" }
const _hoisted_2 = { class: "icon-div" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "dealsDesc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "deals-thumbnail",
            src: _ctx.$getFileSource(_ctx.thumbnail.url),
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}