import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image, Link, Thumbnail} from "@/types";


type Pachinko = {
    data: {
        paragraph: [],
        seo: {},
        midSection: {},
        faqList: [],
        articles: [],
        providerInfo: {},
        gameInfo: {},
        bannerHeader: {}
    }
}

type BannerHeader = {
    title: string,
    subtitle: string,
    description: string,
    button: Link,
    image: Image,
}

type PachinkoSliderItem = {
    title: string,
    subtitle: string,
    description: string,
    image: Thumbnail
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

type MidSection = {
    title: string,
    subtitle: string,
    description: string,
    image: Thumbnail
}

type PachinkoFAQ = {
    title: string,
    description: string
    thumbnail: Image[]
}

type ProviderInfo = {
    title: string,
    subtitle: string,
    description: string,
    provider: []
}

type GameInfo = {
    title: string,
    subtitle: string,
    description: string,
    game: []
}

export default function fetchPachinko() {
    const pachinkoHeader = ref<Pachinko>({} as Pachinko);
    const pachinkoSliderItem = ref<PachinkoSliderItem>({} as PachinkoSliderItem);
    const pachinkoSEO = ref<SEO>({} as SEO);
    const pachinkoBannerHeader = ref<BannerHeader>({} as BannerHeader);
    const pachinkoMisSec = ref<MidSection>({} as MidSection);
    const pachinkoFAQ = ref<PachinkoFAQ>({} as PachinkoFAQ);
    const providerInfo = ref<ProviderInfo>({} as ProviderInfo);
    const gameInfo = ref<GameInfo>({} as GameInfo);
    const loading = ref<boolean>(true);
    const fetchPachinko = () => {
        getSingle<Pachinko>(GET.K8_PACHINKO).then((result) => {
            loading.value = false;
            pachinkoHeader.value = camelizeKeys(result.data);
            pachinkoBannerHeader.value = camelizeKeys(result.data.bannerHeader)
            pachinkoSliderItem.value = camelizeKeys(result.data.paragraph)
            pachinkoMisSec.value = camelizeKeys(result.data.midSection)
            pachinkoFAQ.value = camelizeKeys(result.data.faqList)
            gameInfo.value = camelizeKeys(result.data.gameInfo)
            providerInfo.value = camelizeKeys(result.data.providerInfo)
            pachinkoSEO.value = camelizeKeys(result.data.seo)
        });
    };
    fetchPachinko();
    return {
        pachinkoHeader,
        pachinkoBannerHeader,
        pachinkoSliderItem,
        pachinkoMisSec,
        pachinkoFAQ,
        gameInfo,
        providerInfo,
        pachinkoSEO,
        loading,
        fetchPachinko
    }
}
