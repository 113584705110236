
import { defineComponent } from "vue";
import fetchHomeBonusesByCategory from "@/misc/composition/fetchHomeBonusByCategories";
import fetchK8PromotionInfo from "@/misc/composition/fetchPromotion";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import PopularGamesSlider from "@/components/PopularGamesSlider/PopularGamesSlider.vue";

export default defineComponent({
  name: "Home",
  components: {
    SectionHeading,
    PopularGamesSlider
  },
  setup() {
    const { bonuses, loading } = fetchHomeBonusesByCategory('Long Term Bonuses', 50);
    const { k8PromotionInfo, loading: titleLoading } = fetchK8PromotionInfo();

    return {
      bonuses,
      loading,
      titleLoading,
      k8PromotionInfo
    };
  },
});
