import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff2546a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex characterList justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CharacteristicsEsports = _resolveComponent("CharacteristicsEsports")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeading, {
      class: "subtitle text-start",
      title: _ctx.info.title,
      subtitle: _ctx.info.subtitle,
      description: _ctx.info.description
    }, null, 8, ["title", "subtitle", "description"]),
    _createVNode(_component_a_row, {
      justify: "center",
      type: "flex",
      gutter: [20, 30]
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.provider, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            xl: 12,
            xs: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_CharacteristicsEsports, {
                  thumbnail: item.image,
                  title: item.title,
                  subtitle: item.description
                }, null, 8, ["thumbnail", "title", "subtitle"])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}