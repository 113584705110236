
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchPachinko from "@/misc/composition/fetchPachinko";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import CardGamesSlider from "@/components/CardGameSlider/CardGamesSlider.vue";
import CallToActionSection from "@/components/CallToActionSection/CallToActionSection.vue";
import SectionFaqHorseBetting from "@/components/SectionFAQHorseBetting/SectionFaqHorseBetting.vue";
import SectionInfoPachinko from "@/components/SectionInfo/SectionInfoPachinko.vue";
import SectionTwoColumnWithImage from "@/components/SectionTwoColumnWithImage/SectionTwoColumnWithImage.vue";
import SectionGameInfo from "@/components/SectionGameInfo/SectionGameInfo.vue";

export default defineComponent({
  name: "Pachinko",
  components: {
    LoadingPageAnim,
    MainHeader,
    CardGamesSlider,
    CallToActionSection,
    SectionFaqHorseBetting,
    SectionInfoPachinko,
    SectionGameInfo,
    SectionTwoColumnWithImage,
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "パチンコ"
    Object.keys(this.pachinkoSEO).forEach((k, i) => {
      let d = Object.values(this.pachinkoSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{ rel: 'canonical', href: 'https://www.k8club.io/pachinko'}],
      title: title,
      meta: metaTags
    }
  },
  setup() {
    const {
      pachinkoHeader,
      pachinkoBannerHeader,
      pachinkoSliderItem,
      pachinkoMisSec,
      providerInfo,
      gameInfo,
      pachinkoSEO,
      loading
    } = fetchPachinko();

    return {
      fetchPachinko,
      pachinkoHeader,
      pachinkoBannerHeader,
      pachinkoSliderItem,
      pachinkoMisSec,
      providerInfo,
      gameInfo,
      pachinkoSEO,
      loading
    }
  },
});
