import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-006d1b1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popular-game-card d-flex" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      target: "_blank",
      href: _ctx.href
    }, [
      (_ctx.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "popular-games-thumbnail",
            src: _ctx.$getFileSource(_ctx.thumbnail.url),
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
    ], 8, _hoisted_2)
  ]))
}