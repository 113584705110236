
import {defineComponent} from "vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import fetchK8GameList from "@/misc/composition/fetchK8GameList";
import CardCasinoOffer from "../../components/CardCasinoOffer/CardCasinoOffer.vue";

export default defineComponent({
  name: "SectionBlockchain",
  components: {
    CardCasinoOffer,
  },
  setup() {
    const {k8GameList, loading: k8GameListLoading} = fetchK8GameList({gameType: 'slot'});
    const {titles, loading: titleLoading} = fetchHomeTitles();
    return {
      titleLoading,
      titles,
      k8GameList,
      k8GameListLoading
    };
  },
});
