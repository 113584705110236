
import {defineComponent, PropType} from "vue";
import TwoColumnInfo from "@/components/TwoColumnInfo/TwoColumnInfo.vue";

export default defineComponent({
  name: "SectionBlockchain",
  components: {
    TwoColumnInfo,
  },
  props: {
    info:  Object as PropType<String | Object>,
  },
});
