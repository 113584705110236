
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchK8PromotionInfo from "@/misc/composition/fetchPromotion";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionInfo from "@/components/SectionInfo/SectionInfo.vue";
import OneArticleSection from "@/components/OneArticleSection/OneArticleSection.vue";
import SectionLongTermPromo from "@/components/SectionLongTermPromo/SectionLongTermPromo.vue";
import VipLevelCard from "@/components/VipLevelCard/VipLevelCard.vue";
import SectionVipPromo from "@/components/SectionVipPromo/SectionVipPromo.vue";

export default defineComponent({
  name: "Promotion",
  components: {
    CasinoHeadBanner,
    LoadingPageAnim,
    SectionInfo,
    OneArticleSection,
    SectionLongTermPromo,
    VipLevelCard,
    SectionVipPromo
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "K8オンラインカジノのプロモーション"
    Object.keys(this.promoSEO).forEach((k, i) => {
      let d = Object.values(this.promoSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{ rel: 'canonical', href: 'https://www.k8club.io/promotion'}],
      title: title,
      meta: metaTags
    }
  },
  setup() {
    const {
      k8PromotionInfo,
      promoSEO,
      pageInfo,
      loading: k8PromotionInfoLoading
    } = fetchK8PromotionInfo();
    return {
      k8PromotionInfo,
      promoSEO,
      pageInfo,
      k8PromotionInfoLoading
    };
  },
});
