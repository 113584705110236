
import {defineComponent} from "vue";
import fetchHomeBonusesByCategory from "@/misc/composition/fetchHomeBonusByCategories";
import fetchK8PromotionInfo from "@/misc/composition/fetchPromotion";
import SectionHeading from "../../components/SectionHeading/SectionHeading.vue";
import BestDealsCards from "@/components/BestDealsCards/BestDealsCards.vue";

export default defineComponent({
  name: "Home",
  components: {
    SectionHeading,
    BestDealsCards,
  },
  setup() {
    const {bonuses, loading} = fetchHomeBonusesByCategory('VIP Bonus', 50);
    const {k8PromotionInfo, loading: titleLoading} = fetchK8PromotionInfo();

    return {
      bonuses,
      loading,
      titleLoading,
      k8PromotionInfo
    };
  },
});
