import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image, Link} from "@/types";

type K8PromotionInfo = {
    data: {
        paragraph: []
        bannerHeader: {
            title: string,
            subtitle: string,
            description: string,
            button: Link,
            image: Image,
        },
        seo: {},
        pageInfo: {},
        longTermPromotion: {
            title: string,
            description: string,
            subtitle: string,
            image: Image,
        },
        vipLevel: {
            title: string,
            description: string,
            subtitle: string,
            image: Image,
        },
        vipPromotion: {
            title: string,
            description: string,
            subtitle: string,
            image: Image,
        },
    }
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

type PageInfo = {
    title: string,
    subtitle: string,
    description: string
}

export default function fetchK8PromotionInfo() {
    const k8PromotionInfo = ref<K8PromotionInfo>({} as K8PromotionInfo);
    const promoSEO = ref<SEO>({} as SEO);
    const pageInfo = ref<PageInfo>({} as PageInfo);
    const loading = ref<boolean>(true);
    const fetchK8PromotionInfo = () => {
        getSingle<K8PromotionInfo>(GET.K8_PROMOTION).then((result) => {
            loading.value = false;
            k8PromotionInfo.value = camelizeKeys(result.data)
            promoSEO.value = camelizeKeys(result.data.seo)
            pageInfo.value = camelizeKeys(result.data.pageInfo)
        });
    };
    fetchK8PromotionInfo();
    return {
        k8PromotionInfo,
        promoSEO,
        pageInfo,
        loading,
        fetchK8PromotionInfo
    }
}
