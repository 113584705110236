import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0192b5e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_PopularGamesSlider = _resolveComponent("PopularGamesSlider")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (!_ctx.titleLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_SectionHeading, {
                  class: "section-heading",
                  title: _ctx.k8PromotionInfo.longTermPromotion.title,
                  subtitle: _ctx.k8PromotionInfo.longTermPromotion.subtitle,
                  description: _ctx.k8PromotionInfo.longTermPromotion.description
                }, null, 8, ["title", "subtitle", "description"])
              ]))
            : _createCommentVNode("", true)
        ], true),
        _createVNode(_component_PopularGamesSlider, { items: _ctx.bonuses }, null, 8, ["items"])
      ]))
    : _createCommentVNode("", true)
}