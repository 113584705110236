
import {defineComponent, PropType} from "vue";
import {Thumbnail} from "@/types"
import SectionWithImage from "@/components/SectionWithImage/SectionWithImage.vue";
import FAQ from "@/components/FAQ/FAQ.vue";
import fetchPachinko from "@/misc/composition/fetchPachinko";

export default defineComponent({
  name: "Home",
  components: {

    SectionWithImage,
    FAQ,
  },
  props: {
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
  },
  setup() {
    const {
      pachinkoHeader,
      pachinkoBannerHeader,
      pachinkoSliderItem,
      pachinkoMisSec,
      pachinkoFAQ,
      gameInfo,
      providerInfo,
      pachinkoSEO,
      loading,
    } = fetchPachinko();
    return {
      pachinkoHeader,
      pachinkoSEO,
      pachinkoBannerHeader,
      pachinkoSliderItem,
      pachinkoMisSec,
      pachinkoFAQ,
      gameInfo,
      providerInfo,
      loading
    }
  }
});
