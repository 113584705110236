import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e2c138"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "container container-characteristics" }
const _hoisted_6 = { class: "container container-characteristics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_casino_head_banner = _resolveComponent("casino-head-banner")!
  const _component_OneArticleSection = _resolveComponent("OneArticleSection")!
  const _component_SectionSlotCasino = _resolveComponent("SectionSlotCasino")!
  const _component_SectionInfo = _resolveComponent("SectionInfo")!
  const _component_SectionGameInfo = _resolveComponent("SectionGameInfo")!
  const _component_SectionTwoColumnWithImage = _resolveComponent("SectionTwoColumnWithImage")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.k8SlotInfoLoading }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            (!_ctx.k8SlotInfoLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_casino_head_banner, {
                    class: "my-auto",
                    title: _ctx.k8SlotInfo.bannerHeader.title,
                    subtitle: _ctx.k8SlotInfo.bannerHeader.subtitle,
                    description: _ctx.k8SlotInfo.bannerHeader.description,
                    href: _ctx.k8SlotInfo.bannerHeader.button.href,
                    buttonName: _ctx.k8SlotInfo.bannerHeader.button.name,
                    "bg-images": _ctx.k8SlotInfo.bannerHeader.image
                  }, null, 8, ["title", "subtitle", "description", "href", "buttonName", "bg-images"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_OneArticleSection, { "page-info": _ctx.pageInfo }, null, 8, ["page-info"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SectionSlotCasino)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SectionInfo, { info: _ctx.k8SlotInfo }, null, 8, ["info"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SectionGameInfo, { "game-info": _ctx.gameInfo }, null, 8, ["game-info"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_SectionTwoColumnWithImage, { info: _ctx.providerInfo }, null, 8, ["info"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}