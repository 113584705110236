import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image, Link} from "@/types";

type K8JapaneseDepositWithdraw = {
    data: {
        bannerHeader: {
            title: string,
            subtitle: string,
            description: string,
            button: Link,
            image: Image,
        },
        Seo: {},
        PageInfo: {},
        bankInfo: [],
        WarningInfo: {}
    }
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

type PageInfo = {
    title: string,
    subtitle: string,
    description: string
}

type WarningInfo = {
    title: string,
    subtitle: string,
    description: string
}

export default function fetchJapaneseDepositWithdraw() {
    const japaneseDepositWithdraw = ref<K8JapaneseDepositWithdraw>({} as K8JapaneseDepositWithdraw);
    const japaneseDepositWithdrawSEO = ref<SEO>({} as SEO);
    const pageInfo = ref<PageInfo>({} as PageInfo);
    const warningInfo = ref<WarningInfo>({} as WarningInfo);
    const loading = ref<boolean>(true);
    const fetchJapaneseDepositWithdraw = () => {
        getSingle<K8JapaneseDepositWithdraw>(GET.K8_JAPANESE_DEPOSIT_WITHDRAW).then((result) => {
            loading.value = false;
            pageInfo.value = camelizeKeys(result.data.PageInfo)
            warningInfo.value = camelizeKeys(result.data.WarningInfo)
            japaneseDepositWithdraw.value = camelizeKeys(result.data)
            japaneseDepositWithdrawSEO.value = camelizeKeys(result.data.Seo)
        });
    };
    fetchJapaneseDepositWithdraw();
    return {
        japaneseDepositWithdraw,
        japaneseDepositWithdrawSEO,
        pageInfo,
        warningInfo,
        loading,
        fetchJapaneseDepositWithdraw
    }
}
