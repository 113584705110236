
import {defineComponent, PropType} from "vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";

export default defineComponent({
  name: "OneArticleSection",
  components: {
    SectionHeading,
  },
  props: {
    pageInfo:  Object as PropType<String | Object>,
  },
});
