import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5940d630"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_bitcoin_info = _resolveComponent("bitcoin-info")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_SectionHeading, {
            class: "section-heading",
            title: _ctx.k8PromotionInfo.vipLevel.title,
            subtitle: _ctx.k8PromotionInfo.vipLevel.subtitle,
            description: _ctx.k8PromotionInfo.vipLevel.description
          }, null, 8, ["title", "subtitle", "description"])
        ]),
        _createVNode(_component_a_row, {
          justify: "center",
          type: "flex",
          gutter: [20, 30]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vipLevelList, (item) => {
              return (_openBlock(), _createBlock(_component_a_col, {
                xl: 8,
                sm: 12,
                xs: 24,
                key: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_bitcoin_info, {
                    thumbnail: item.thumbnail,
                    title: item.title,
                    subtitle: item.description
                  }, null, 8, ["thumbnail", "title", "subtitle"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}