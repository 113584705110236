import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-701b7b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_casino_head_banner = _resolveComponent("casino-head-banner")!
  const _component_OneArticleSection = _resolveComponent("OneArticleSection")!
  const _component_SectionTwoColumnWithImage2 = _resolveComponent("SectionTwoColumnWithImage2")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_a_layout_content, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_casino_head_banner, {
                    class: "my-auto",
                    title: _ctx.japaneseDepositWithdraw.bannerHeader.title,
                    subtitle: _ctx.japaneseDepositWithdraw.bannerHeader.subtitle,
                    description: _ctx.japaneseDepositWithdraw.bannerHeader.description,
                    href: '',
                    buttonName: '',
                    "bg-images": _ctx.japaneseDepositWithdraw.bannerHeader.image
                  }, null, 8, ["title", "subtitle", "description", "bg-images"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_OneArticleSection, { "page-info": _ctx.pageInfo }, null, 8, ["page-info"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_SectionTwoColumnWithImage2, {
                    info: _ctx.japaneseDepositWithdraw.bankInfo[0]
                  }, null, 8, ["info"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_SectionTwoColumnWithImage2, {
                    info: _ctx.japaneseDepositWithdraw.bankInfo[1]
                  }, null, 8, ["info"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_OneArticleSection, { "page-info": _ctx.warningInfo }, null, 8, ["page-info"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}