import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b8e9af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pachinko" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoColumnInfo = _resolveComponent("TwoColumnInfo")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, {
      justify: "center",
      type: "flex",
      gutter: [5, 5]
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.articles, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            xl: 10,
            xs: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TwoColumnInfo, {
                description: item.description,
                title: item.title
              }, null, 8, ["description", "title"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}