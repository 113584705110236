import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopularGameCard = _resolveComponent("PopularGameCard")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    "slides-per-view": 3,
    grid: {
          rows: 2,
          fill: 'row',
        },
    spaceBetween: 35,
    grabCursor: true,
    pagination: {
          clickable: true
        },
    breakpoints: {
      '@0.00': {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      '@0.75': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '@1.00': {
        slidesPerView: 3,
        spaceBetween: 35,
      },
    },
    class: "mySwiper"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: item,
          class: "swiper-slide"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PopularGameCard, {
              title: item.title,
              thumbnail: item.thumbnail,
              description: item.description,
              href: item.bonusLink
            }, null, 8, ["title", "thumbnail", "description", "href"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}