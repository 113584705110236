
import { defineComponent, PropType } from "vue";
import { Thumbnail } from "../../types";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: "/"
    },
    image: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    badgeText: {
      type: String,
      required: false,
    },
  },
});
