
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchWalletInfo from "@/misc/composition/fetchWallet";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionTwoColumnWithImage2 from "@/components/SectionTwoColumnWithImage2/SectionTwoColumnWithImage2.vue";

export default defineComponent({
  name: "Wallet Info",
  components: {
    SectionTwoColumnWithImage2,
    CasinoHeadBanner,
    LoadingPageAnim,
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "ウォレットインフォ"
    Object.keys(this.walletSEO).forEach((k, i) => {
      let d = Object.values(this.walletSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{rel: 'canonical', href: 'https://www.k8club.io/wallet-info'}],
      title: title,
      meta: metaTags,
    }
  },
  setup() {
    const {
      walletInfo,
      walletSEO,
      loading
    } = fetchWalletInfo();
    return {
      walletInfo,
      walletSEO,
      loading
    };
  },
});
