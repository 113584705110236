import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02904e3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "badge-card"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content d-flex flex-column text-center" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "description truncate-overflow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.badgeText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.badgeText), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "card-game-thumbnail",
            src: _ctx.$getFileSource(_ctx.image.url),
            alt: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_a_tooltip, { placement: "right" }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
        ]),
        _: 1
      })
    ])
  ]))
}