import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Thumbnail} from "@/types";


type VipLevels = {
    data: {
        title: string;
        description: string;
        thumbnail: Thumbnail;

    }
}

export default function fetchVipLvlList() {
    const vipLevelList = ref<VipLevels>({} as VipLevels);
    const loading = ref<boolean>(true);
    const fetchVipLvlList = () => {
        return getSingle<VipLevels>(GET.K8_VIP_LEVEL).then((result) => {
            loading.value = false;
            vipLevelList.value = camelizeKeys(result.data);
        });
    };
    fetchVipLvlList();
    return {vipLevelList, loading, fetchVipLvlList};
}
