import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TwoColumnInfo = _resolveComponent("TwoColumnInfo")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, {
      justify: "center",
      type: "flex",
      gutter: [20, 5]
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.paragraph, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            xl: 12,
            xs: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TwoColumnInfo, {
                description: item.description,
                title: item.title
              }, null, 8, ["description", "title"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}