
import {defineComponent, PropType} from "vue";
import CharacteristicsEsports from "@/components/CharacteristicsEsports/CharacteristicsEsports.vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";

export default defineComponent({
  name: "SectionTwoColumnWithImage",
  components: {
    CharacteristicsEsports,
    SectionHeading,
  },
  props: {
    info:  Object as PropType<String | Object>,
    justify: {
      type: String,
      default: "center"
    }
  },
});
