
import {defineComponent, PropType} from "vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";
import GameInfo from "@/components/GameInfo/GameInfo.vue";

export default defineComponent({
  name: "SectionGameInfo",
  components: {
    GameInfo,
    SectionHeading,
  },
  props: {
    gameInfo:  Object as PropType<String | Object>,
  },
});
