
import {defineComponent} from "vue";
import {kebabizeColon} from "@/misc/utilis/api/helpers";
import fetchK8SlotInfo from "@/misc/composition/fetchK8SlotInfo";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import SectionSlotCasino from "@/components/SectionSlotCasino/SectionSlotCasino.vue";
import CasinoHeadBanner from "@/components/CasinoHeadBanner/CasinoHeadBanner.vue";
import SectionInfo from "@/components/SectionInfo/SectionInfo.vue";
import SectionTwoColumnWithImage from "@/components/SectionTwoColumnWithImage/SectionTwoColumnWithImage.vue";
import SectionGameInfo from "@/components/SectionGameInfo/SectionGameInfo.vue";
import OneArticleSection from "@/components/OneArticleSection/OneArticleSection.vue";

export default defineComponent({
  name: "Slot Casino",
  components: {
    OneArticleSection,
    SectionTwoColumnWithImage,
    CasinoHeadBanner,
    LoadingPageAnim,
    SectionSlotCasino,
    SectionInfo,
    SectionGameInfo,
  },
  metaInfo: function () {
    // make the metaTag from the api
    let metaTags: Array<any> = []
    let title: string = "スロットマシン"
    Object.keys(this.slotSEO).forEach((k, i) => {
      let d = Object.values(this.slotSEO)[i];
      if (k != 'id' && k != 'ogSiteName') {
        if (k.includes('og')) {
          metaTags.push({property: kebabizeColon(k), content: d})
        } else {
          metaTags.push({name: kebabizeColon(k), content: d})
        }
        if (k == "title") {
          title = d
        }
      }
      if (k == 'ogSiteName') {
        metaTags.push({property: 'og:site_name', content: d})
      }
    })
    return {
      link: [{ rel: 'canonical', href: 'https://www.k8club.io/slot-casino'}],
      title: title,
      meta: metaTags,
    }
  },
  setup() {
    const {
      k8SlotInfo,
      slotSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      loading: k8SlotInfoLoading
    } = fetchK8SlotInfo();
    return {
      k8SlotInfo,
      slotSEO,
      providerInfo,
      gameInfo,
      pageInfo,
      k8SlotInfoLoading
    };
  },
});
