
import {defineComponent} from "vue";
import CardCasinoOffer from "../../components/CardCasinoOffer/CardCasinoOffer.vue";
import fetchHomeTitles from "../../misc/composition/fetchHomeSectionTitles";
import fetchK8GameList from "@/misc/composition/fetchK8GameList";

export default defineComponent({
  name: "SectionLiveCasino",
  components: {
    CardCasinoOffer,
  },
  setup() {
    const {k8GameList, loading} = fetchK8GameList({gameType: 'live-casino'});
    const {titles, loading: titleLoading} = fetchHomeTitles();
    return {loading, titleLoading, titles, k8GameList};
  },
});
