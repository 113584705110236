import {getSingle} from "../utilis/api/wrappers";
import {camelizeKeys} from "../utilis/api/helpers";
import {GET} from "../utilis/api/endpoints";
import {ref} from "vue";
import {Image, Link} from "@/types";

type K8LiveCasinoInfo = {
    data: {
        paragraph: []
        bannerHeader: {
            title: string,
            subtitle: string,
            description: string,
            button: Link,
            image: Image,
        },
        seo: {},
        providerInfo: {},
        gameInfo: {},
        pageInfo: {},
    }
}

type SEO = {
    jsonLd: string,
    metaTitle: string,
    metaDescription: string,
    ogImage: string,
    ogTitle: string,
    ogType: string,
    ogUrl: string,
    seoUid: string,
    twitterCard: string,
    twitterSite: string,
    twitterImage: string,
    twitterTitle: string,
    twitterDescription: string,
}

type ProviderInfo = {
    title: string,
    subtitle: string,
    description: string,
    provider: []
}

type GameInfo = {
    title: string,
    subtitle: string,
    description: string,
    game: []
}

type PageInfo = {
    title: string,
    subtitle: string,
    description: string
}

export default function fetchK8LiveCasinoInfo() {
    const k8LiveCasinoInfo = ref<K8LiveCasinoInfo>({} as K8LiveCasinoInfo);
    const liveCasinoSEO = ref<SEO>({} as SEO);
    const providerInfo = ref<ProviderInfo>({} as ProviderInfo);
    const gameInfo = ref<GameInfo>({} as GameInfo);
    const pageInfo = ref<PageInfo>({} as PageInfo);
    const loading = ref<boolean>(true);
    const fetchK8LiveCasinoInfo = () => {
        getSingle<K8LiveCasinoInfo>(GET.K8_LIVE_CASINO_INFO).then((result) => {
            loading.value = false;
            k8LiveCasinoInfo.value = camelizeKeys(result.data)
            liveCasinoSEO.value = camelizeKeys(result.data.seo)
            providerInfo.value = camelizeKeys(result.data.providerInfo)
            gameInfo.value = camelizeKeys(result.data.gameInfo)
            pageInfo.value = camelizeKeys(result.data.pageInfo)
        });
    };
    fetchK8LiveCasinoInfo();
    return {
        k8LiveCasinoInfo,
        liveCasinoSEO,
        providerInfo,
        gameInfo,
        pageInfo,
        loading,
        fetchK8LiveCasinoInfo
    }
}
